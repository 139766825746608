var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tasks",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "tasks-main"
  }, [_c('div', {
    staticClass: "car"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tasks-logo.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("More tasks,More rewards")))])]), _c('div', {
    staticClass: "task"
  }, [_c('div', {
    staticClass: "task__title"
  }, [_vm._v(_vm._s(_vm.$lang("Official")) + " "), _vm.taskNum > 0 ? _c('em', [_vm._v(_vm._s(_vm.taskNum))]) : _vm._e()]), _vm._l(_vm.nTaskList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "task-item",
      style: {
        background: _vm.themeMainColor(_vm.theme).taskItemBg
      },
      on: {
        "click": function ($event) {
          return _vm.onJoin(item);
        }
      }
    }, [_c('div', {
      staticClass: "task-item__icon",
      style: {
        background: _vm.themeMainColor(_vm.theme).taskItemIconBg
      }
    }, [_c('img', {
      style: {
        filter: `drop-shadow(${_vm.themeMainColor(_vm.theme).taskItemIconColor} 0 32px)`
      },
      attrs: {
        "src": require(`@images/${_vm.theme}/${item.icon}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "task-item-info"
    }, [_c('div', {
      staticClass: "task-item__title"
    }, [_vm._v(_vm._s(item.label))]), _c('div', {
      staticClass: "task-item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/icon-coin.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(_vm.setting.reward.joined[item.target]) + " " + _vm._s(_vm.setting.coin) + " ")])]), _c('div', {
      staticClass: "task-item__check",
      class: _vm.userData[`joined_${item.target}`] == 0 && 'active'
    }, [_vm.userData[`joined_${item.target}`] == 0 ? _c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    }) : _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1)]);
  })], 2), _c('div', {
    staticClass: "task extra"
  }, [_c('div', {
    staticClass: "task__title"
  }, [_vm._v(_vm._s(_vm.$lang("Extra Tasks")) + " "), _vm.extraTaskNum > 0 ? _c('em', [_vm._v(_vm._s(_vm.extraTaskNum))]) : _vm._e()]), _vm._l(_vm.taskList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "task-item",
      style: {
        background: _vm.themeMainColor(_vm.theme).exraTaskItemBg
      },
      on: {
        "click": function ($event) {
          return _vm.onExtralJoin(item, 0);
        }
      }
    }, [_c('div', {
      staticClass: "task-item__icon",
      style: {
        background: _vm.themeMainColor(_vm.theme).taskItemIconBg
      }
    }, [_c('img', {
      style: {
        filter: `drop-shadow(${_vm.themeMainColor(_vm.theme).taskItemIconColor} 0 32px)`
      },
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "task-item-info"
    }, [_c('div', {
      staticClass: "task-item__title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "task-item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/icon-coin.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(item.reward) + " " + _vm._s(_vm.setting.coin) + " ")])]), _c('div', {
      staticClass: "task-item__check",
      class: item.completed == 0 && 'active'
    }, [item.completed == 0 ? _c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    }) : _c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1)]);
  })], 2)]), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };